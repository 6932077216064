import { graphql } from 'gatsby';
import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import getYouTubeID from 'get-youtube-id';
import YouTube from 'react-youtube';
import SEO from '../components/SEO';
import Gallery from '../components/Gallery';

const ProjectStyles = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title h1 {
    text-align: left;
  }

  .role {
    color: grey;
    font-weight: 300;
    display: flex;
    align-items: right;
    justify-content: right;
    gap: 1rem;
  }
  .role span {
    color: white;
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const EmbedStyles = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const serializers = {
  types: {
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeID(url);

      return (
        <EmbedStyles>
          <YouTube videoId={id} />
        </EmbedStyles>
      );
    },
    inlineImage: ({ node }) => (
      <div
        style={{
          display: `${node.display}`,
          width: `${node.width}%`,
          // height: `${node.height}px`,
        }}
        className="image-container"
      >
        <SanityImage {...node.image} className="shadow pixelated-load" />
      </div>
    ),
    gallery: ({ node }) => <Gallery images={node.images} />,
  },
};

export default function SingleProjectPage({ data: { project } }) {
  return (
    <>
      <SEO title={project.name} image={project.image?.asset?.fluid?.src} />
      <ProjectStyles>
        <SanityImage
          {...project.image}
          className="hero-img pixelated-load shadow rounded"
        />
        <div className="content">
          <div className="title">
            <h1>{project.name}</h1>
            <div className="projectInfo">
              <h5 className="role">
                Client: <span>{project.client}</span>
              </h5>
              <h5 className="role">
                Role: <span>{project.role}</span>
              </h5>
            </div>
          </div>
          <BlockContent
            blocks={project._rawContent}
            serializers={serializers}
            projectId="o6ahhntv"
            dataset="production"
          />
        </div>
      </ProjectStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      name
      id
      datePublished
      client
      role
      image {
        ...ImageWithPreview
        alt
        asset {
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawContent
      categories {
        name
        id
      }
    }
  }
`;
