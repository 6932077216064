import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import LightBox from './Lightbox';
import ThumbGrid from './Thumbnails';

const GalleryStyles = styled.div`
  padding-top: 4rem;
  display: block;
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 4rem 1rem;
    margin: 0 2rem;
  }
`;

export default function Gallery({ images }) {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (i) => (e) => {
    setShowLightbox(true);
    setSelectedImage(i);
  };
  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage(null);
  };
  const handlePrevRequest = (i, length) => (e) => {
    setSelectedImage((i - 1 + length) % length);
  };
  const handleNextRequest = (i, length) => (e) => {
    setSelectedImage((i + 1) % length);
  };

  return (
    <GalleryStyles className="gallery">
      <div className="gallery-grid">
        <ThumbGrid images={images} handleOpen={handleOpen} />
        {showLightbox && selectedImage !== null && (
          <LightBox
            images={images}
            handleClose={handleClose}
            handleNextRequest={handleNextRequest}
            handlePrevRequest={handlePrevRequest}
            selectedImage={selectedImage}
          />
        )}
      </div>
    </GalleryStyles>
  );
}
