import React from 'react';

// https://github.com/treyhuffine/lightbox-react/blob/master/src/lightbox-react.js
// https://reactjsexample.com/lightbox-for-components-or-images-built-for-react/
import LightboxReact from 'lightbox-react';
import 'lightbox-react/style.css';
import SanityImage from 'gatsby-plugin-sanity-image';
import styled from 'styled-components';

const LightboxStyles = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  img {
    max-height: 90vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .caption {
    text-align: center;
  }
`;

const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = [];

  images.forEach((node) => {
    console.log(node);
    array.push(
      <LightboxStyles>
        <SanityImage {...node.image} className="pixelated-load" />
        <p className="caption">{node.caption}</p>
      </LightboxStyles>
    );
  });

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  );
};

export default Lightbox;
