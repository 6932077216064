import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SanityImage from 'gatsby-plugin-sanity-image';

const ThumbnailsStyles = styled.div`
  .previewButton {
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    margin: 1rem;
    /* width: 300px; */
    height: 300px;
    box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.3);
  }
  .thumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
  }
`;

const ThumbGrid = ({ images, handleOpen }) =>
  images.map((image, i) => (
    <ThumbnailsStyles>
      {/* <Grid item key={i}> */}
      <div className="gallery-grid-item">
        {console.log(image)}
        <button type="button" onClick={handleOpen(i)} className="previewButton">
          <SanityImage {...image.image} className="pixelated-load thumbnail" />
        </button>
      </div>
      {/* </Grid> */}
    </ThumbnailsStyles>
  ));

ThumbGrid.propTypes = {
  classes: PropTypes.object,
  images: PropTypes.array,
};
export default ThumbGrid;
